<template>
  <b-row 
    class="m-1 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center"
  >
    <!--Modal-->
    <data-gathering-clone-modal
      :selected-area="selectedArea"
    ></data-gathering-clone-modal>

    <data-gathering-card-error-modal
      :errors-data="errorsData"
    ></data-gathering-card-error-modal>

    <b-col 
      class="mb-2 mb-md-0"
      style="min-width: 280px; max-width: 300px;"
      v-for="(area, i) in areasData"
      :key="i"
    >
      <data-gathering-card
        :area-data="area"
        @clone-selected-area="setSelectedArea"
        @set-errors-data ="setErrorsData"
      ></data-gathering-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCard, BCol,
} from 'bootstrap-vue'
import DataGatheringCard from './DataGatheringCard.vue'
import DataGatheringCloneModal from '@/modules/data-gathering/DataGatheringCloneModal.vue'
import { ref } from '@vue/composition-api'
import DataGatheringCardErrorModal from './DataGatheringCardErrorModal.vue'

export default {
  components: {
    BRow,
    BCard,
    BCol,

    DataGatheringCard,
    DataGatheringCloneModal,
    DataGatheringCardErrorModal
  },
  props: {
    areasData: {
      type: Array,
      default: []
    }
  },
  setup() {
    const selectedArea = ref({})
    const errorsData = ref([])
    
    const setSelectedArea = (value) => {
      selectedArea.value = value
    }

    const setErrorsData = (value) => {
      errorsData.value = value
    }

    return {
      selectedArea,
      errorsData,

      setSelectedArea,
      setErrorsData,
    }
  }
}
</script>
