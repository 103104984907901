<template>
  <section id="home-dashboard">
    <b-row class="match-height">
      <b-col
        cols="12"
      >
        <timeline-pipeline></timeline-pipeline>
      </b-col>
      <!-- <b-col
        cols="12"
        md="4"
      >
        <statistic-card-with-area-chart
          v-if="data.sellIn"
          icon="DollarSignIcon"
          :statistic="kFormatter(data.sellIn.analyticsData.value)"
          :statistic-title="data.sellIn.title"
          :chart-data="data.sellIn.series"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <statistic-card-with-area-chart
          v-if="data.sellOut"
          icon="DollarSignIcon"
          color="warning"
          :statistic="kFormatter(data.sellOut.analyticsData.value)"
          :statistic-title="data.sellOut.title"
          :chart-data="data.sellOut.series"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <statistic-card-with-area-chart
          v-if="data.sellInQuantity"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.sellInQuantity.analyticsData.value)"
          :statistic-title="data.sellInQuantity.title"
          :chart-data="data.sellInQuantity.series"
        />
      </b-col> -->
    </b-row>

    <!-- <b-row class="match-height">
      <b-col
        cols="12"
        md="7"
      >
        <b-card>
          <analytics-timeline :data="data.timeline" />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <card-advance-chat></card-advance-chat>
      </b-col>
    </b-row> -->
    
    <b-row>
      <b-col 
        cols="12"
      >
        <data-gathering></data-gathering>
      </b-col>
    </b-row> 
  </section>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol, } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { kFormatter } from '@core/utils/filter.js'
import DataGathering from '@/modules/data-gathering/DataGathering.vue'
import AnalyticsTimeline from '@/modules/analytics/AnalyticsTimeline.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import CardAdvanceChat from '@/modules/chat/CardAdvanceChat.vue'
import TimelinePipeline from '@/modules/timeline/TimelinePipeline.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow, 
    BCol,

    DataGathering,
    AnalyticsTimeline,
    StatisticCardWithAreaChart,
    CardAdvanceChat,
    TimelinePipeline,
  },
  setup() {
    const data = ref({})

    const getFileData = () => {
      import('@/modules/fake-data/home-data.js').then((e) => {
        data.value = e.default
      });
    }

    getFileData()

    return {
      data,

      kFormatter,
    }
  }
}
</script>

<style>

</style>
