<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="3"
        >
          <flat-pickr 
            class="form-control ml-1 date-picker-input text-center"
            :config="datePickerConfig"
            @on-change="changeDate"
            v-model="dateSelected">
          </flat-pickr>
        </b-col>
      </b-row>
    </div>
    
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <data-gathering-card-list
        :areas-data="areasData"
      ></data-gathering-card-list>
    </div>
  </b-card>
</template>

<script>
import { ref, provide } from '@vue/composition-api'
import { BRow, BCol, BCard, } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import { Spanish } from "flatpickr/dist/l10n/es.js"
import DataGatheringCardList from './DataGatheringCardList.vue'
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,

    flatPickr,
    DataGatheringCardList,
    CustomLoading,
  },
  setup() {
    const dateSelected = ref('')
    const areasData = ref([])
    const isLoading = ref(true)

    // Provide
    provide('dateSelected', dateSelected)

    const datePickerConfig = {
      locale: Spanish,
      altInput: true,
      altFormat: 'M Y',
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: 'm-Y',
          // theme: 'dark',
        })
      ]
    }

    const setCurrentMonth = () => {
      let dateObj = new Date();
      let monthNumber = dateObj.getUTCMonth() + 1; //months from 1-12
      let month = monthNumber < 10 ? "0" + monthNumber : monthNumber
      let year = dateObj.getUTCFullYear();

      dateSelected.value = month + "-" + year
    }

    const fetchData = async (monthYear) => {
      try {
        isLoading.value = true
        if (!monthYear) monthYear = dateSelected.value
        const month = monthYear.split('-')[0]
        const year = monthYear.split('-')[1]
        const response = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/get_info`, 
          {
            year,
            month
          })
        const resultData = response.data.result
        areasData.value = resultData
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    const changeDate = (selectedDates, dateStr, instance) => {
      fetchData(dateStr)
    }

    setCurrentMonth()
    fetchData()

    return {
      dateSelected,
      areasData,
      datePickerConfig,
      isLoading,

      changeDate,
    }
  }
}
</script>

<style lang="scss" scoped>
.date-picker-input {
  max-width: 11rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>